.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circular-menu {
  left: -1.5em;
  top: -1.5em;
  position: absolute;
  opacity: 0.4;
}

.circular-menu:hover {
  opacity: 1;
}

.circular-menu.active {
  opacity: 1;
}

.circular-menu .floating-btn {
  display: block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 hsla(0, 0%, 0%, 0.26);
  color: #777;
  text-align: center;
  line-height: 3.4;
  cursor: pointer;
  outline: 0;
}

.circular-menu.active .floating-btn {
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, 0.3);
}

.circular-menu .floating-btn:active {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, 0.4);
}

.circular-menu .floating-btn i {
  font-size: 1.3em;
  transition: transform 0.2s;
}

.circular-menu.active .floating-btn i {
  transform: rotate(-45deg);
}

/* .circular-menu:after {
  display: block;
  content: " ";
  width: 3em;
  height: 3em;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.circular-menu.active:after {
  transform: scale3d(5.5, 5.5, 1);
  transition-timing-function: cubic-bezier(0.68, 1.55, 0.265, 1);
} */

.circular-menu .items-wrapper {
  padding: 0;
  margin: 0;
}

.circular-menu .menu-item {
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  z-index: -1;
  display: block;
  text-decoration: none;
  color: hsl(0, 0%, 100%);
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5;
  background-color: hsla(0, 0%, 0%, 0.7);
  transition: transform 0.3s ease, background 0.2s ease;
}

.circular-menu .menu-item:hover {
  background-color: hsla(0, 0%, 0%, 0.9);
}

.circular-menu.active .menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.circular-menu.active .menu-item:nth-child(1) {
  transform: translate3d(1em, -7em, 0);
}

.circular-menu.active .menu-item:nth-child(2) {
  transform: translate3d(-3.5em, -6.3em, 0);
}

.circular-menu.active .menu-item:nth-child(3) {
  transform: translate3d(-6.5em, -3.2em, 0);
}

.circular-menu.active .menu-item:nth-child(4) {
  transform: translate3d(-7em, 1em, 0);
}

/**
 * The other theme for this menu
 */

.circular-menu.circular-menu-left {
  right: auto;
  left: 1em;
}

.circular-menu.circular-menu-left .floating-btn {
  background-color: hsl(217, 89%, 61%);
}

.circular-menu.circular-menu-left:after {
  background-color: hsl(217, 89%, 61%);
}

.circular-menu.circular-menu-left.active .floating-btn i {
  transform: rotate(90deg);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(1) {
  transform: translate3d(-1em, -7em, 0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(2) {
  transform: translate3d(3.5em, -6.3em, 0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(3) {
  transform: translate3d(6.5em, -3.2em, 0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(4) {
  transform: translate3d(7em, 1em, 0);
}

.dimension-length {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}

.dimension-length div {
  border-bottom: 1px solid #000;
  text-align: center;
  width: 100%;
}

.dimension-length div span {
  text-align: center;
  border-radius: 10px;
  color: black;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 10px;
  background-color: rgba(255, 255, 255, 0.6);
}

.dimension-height {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.dimension-height div {
  border-left: 1px solid #000;
  height: 100%;
  display: flex;
  align-items: center;
}

.dimension-height div span {
  text-align: center;
  border-radius: 10px;
  color: black;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 10px;
  background-color: rgba(255, 255, 255, 0.6);
}

.dimension-width {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}

.dimension-width div {
  border-bottom: 1px solid #000;
  text-align: center;
  width: 100%;
}

.dimension-width div span {
  text-align: center;
  border-radius: 10px;
  color: black;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 10px;
  background-color: rgba(255, 255, 255, 0.6);
}
